import HtmlCss from '@/components/task-templates/HtmlCss'

export default [
  {
    component: HtmlCss,
    name: 'Первая страница',
    text: 'Сверстайте страницу:',
    html: `
      <div>Hello, world!</div>
    `
  }
]
