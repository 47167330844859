<template>
  <h2 class="m0 mt-medium">{{ task.name }}</h2>
  <div v-html="task.text" class="mt-medium" />
  <Browser :html="task.html" class="mt" />
</template>

<script>
import Browser from './Browser'

export default {
  name: 'Info',
  components: {
    Browser
  },
  props: ['task'],
  setup(props) {
    return {
      comp: props.task.component
    }
  }
}
</script>
