<template>
  <div class="browser">
    <div class="top flex-sb">
      <div>
        <img src="./img/page.png" alt="">
      </div>
      <div>
        <img src="./img/controls.png" alt="">
      </div>
    </div>
    <div class="address flex-sb">
      <div>
        <img src="./img/addr-control.png" alt="">
      </div>
      <div class="address-line">
        file://example.html
      </div>
      <div>
        <img src="./img/settings.png" alt="">
      </div>
    </div>
    <div class="bbody" v-html="html">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Browser',
  props: {
    html: String
  }
}
</script>

<style scoped>
.browser {
  border: 1px solid #bdbfc2;
}
.top {
  background: #dee1e6;
  height: 43px;
}
.address {
  height: 38px;
  background: white;
  border-bottom: 1px solid #dadce0;
}
.bbody {
  padding: 8px;
  min-height: 500px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.address-line {
  background: #f1f3f4;
  height: 28px;
  color: #555555;
  margin: 4px 0px;
  padding: 2px 11px;
  flex: 1;
  border-radius: 9999px;
  font-size: 16px;
}
</style>
